// main imports
import * as React from "react";
import { graphql } from "gatsby";

// plugin imports
import { Trans, useTranslation } from "react-i18next";
import { Link, useI18next } from "gatsby-plugin-react-i18next";
import { StaticImage } from "gatsby-plugin-image";
import { Parallax } from "react-scroll-parallax";

// style imports
import "../index.scss";

// svg imports
// import Favicon from "../../images/favicon.svg";

// components imports
import Layout from "../../components/Layout/Layout.js";
import Seo from "../../components/Seo/Seo.js";
import BannerAnouncements from "../../components/Layout/BannerAnouncements";
import PopUp from "../../components/PopUp/PopUp";
// import ChristmasSchedule from "../../components/Contents/Christmas";
// import DinnerSchedule from "../../components/Contents/DinnerJuly";

// page
export default function IndexPage() {
	const { t } = useTranslation();
	const { language } = useI18next();

	const parallaxSettings = {
		speed: 20,
		easing: "easeInOut",
		className: "parallaxSection",
		onEnter: (element) => element.style,
	};

	const servicesList = [
		t("bakery"),
		t("juice bar"),
		t("deli shop"),
		t("boutique pop up"),
		t("kids corner"),
		t("flower bar"),
		t("take away"),
		t("private chefs"),
		t("events"),
	];

	const menusList = [
		{
			name: t("breakfast menu"),
			text: t("from 09:00 to 12:00"),
			link: "breakfast",
		},
		{
			name: t("lunch menu"),
			text: t("from 12:00 to 16:00"),
			link: "lunch",
		},
		// {
		// 	name: t("dinner menu"),
		// 	link: "dinner",
		// 	text: t("from 19:00 to 22:00"),
		// },
		{
			name: t("drinks menu"),
			text: t("let's have a drink"),
			link: "drinks",
		},
		{
			name: t("wine menu"),
			text: t("red. white. rose.."),
			link: "wines",
		},
		{
			name: t("juicery menu"),
			text: t("a fresh new start"),
			link: "juicery",
		},
		{
			name: t("coffee menu"),
			text: t("stay caffeinated as always"),
			link: "coffee",
		},
	];

	const contactList = [
		{
			name: t("bookings here"),
			text: t("bookings page"),
			link: "",
			internalLink: "/cafe/reserva",
		},
		{
			name: t("phone"),
			text: "+34 689 464 114",
			link: "tel:+34689464114",
		},
		{
			name: t("e-mail"),
			text: "info@bossaestepona.com",
			link: "mailto:porto@bossaestepona.com",
		},
		{
			name: t("instagram"),
			text: "@bossaestepona",
			link: "https://www.instagram.com/bossaestepona/",
		},
		{
			name: t("address"),
			text: "Av. Parque Selwo, Calle Lima Local 5, Estepona",
			link: "https://goo.gl/maps/u86vRQLU4C5rSsP59",
		},
	];

	const specialDates = (
		<>
			<div className="contactList" style={{ marginTop: "2rem" }}>
				<div>
					<span className="bold">
						<Trans>opening hours</Trans>.
					</span>
				</div>
				<div>
					<span>
						<Trans>mon to wed</Trans>{" "}
					</span>
					<span>
						<Trans>09am to 04.30pm</Trans>.
						{/* <span className="light"> kitchen 10h to 14h.</span> */}
					</span>
				</div>
				<div>
					<span>
						<Trans>thu & fri</Trans>{" "}
					</span>
					<span>
						<Trans>09.30am to 04.30pm</Trans>.{" "}
						<span className="light">
							<Trans></Trans> <Trans></Trans>
						</span>
					</span>
				</div>
				<div>
					<span>
						<Trans>thu & fri dinner</Trans>{" "}
					</span>
					<span>
						<Trans>07pm to 10pm</Trans>.{" "}
						<span className="light">
							<Trans>kitchen</Trans> <Trans>07pm to 10pm</Trans>.
						</span>
					</span>
				</div>
				<div>
					<span>
						<Trans>sat and sun</Trans>{" "}
					</span>
					<span>
						<Trans>10am to 04.30pm</Trans>.
						{/* <span className="light"> kitchen 10h to 14h.</span> */}
					</span>
				</div>
				{/* <div>
					<span>
						<Trans>
							<u>sat 20/05 closed</u> for private event
						</Trans>
						.
					</span>
				</div> */}
				{/* <ChristmasSchedule /> */}
				{/* <DinnerSchedule /> */}
			</div>
		</>
	);

	return (
		<Layout local="cafe">
			<Seo lang={language} title="Cafe" />
			{/* Actualizar la hora respetando el formato */}
			<PopUp />
			<div className="bodyContainer">
				{/* <p className="bold">Bold text</p> */}

				<section>
					{/* <BannerAnouncements /> */}

					<Parallax {...parallaxSettings}>
						<p>
							<Trans>
								where family, food and wholesome products unite into one
							</Trans>
							.<br></br>
							<Trans>we offer a little something for everyone!</Trans>
						</p>

						<div className="servicesFlex bold">
							{servicesList.map((service) => {
								return <div key={service}>{service}.</div>;
							})}
						</div>
					</Parallax>

					<StaticImage
						src={`../../images/cafe/landing2.jpg`}
						alt={t(
							"a warmth trendy restaurant interior, two girls enjoy a coffee in the back"
						)}
						className={"landingBgImage"}
					/>
				</section>

				<section id="menus">
					<Parallax {...parallaxSettings}>
						<h2>
							<Trans>menus</Trans>.
						</h2>

						<div className="menuList">
							{/* <div>
								<span className="bold">
									<Trans>food menu</Trans>.
								</span>{" "}
								<span className="light">
									<Trans>
										we change it daily, <br></br>
										for more information please{" "}
										<Link to="#contact" style={{ textDecoration: "none" }}>
											get in touch
										</Link>
										.
									</Trans>
								</span>
							</div> */}
							{menusList.map((menu) => {
								return (
									<div key={menu.name}>
										<Link to={`/menus/${menu.link}`} className="bold">
											{menu.name}
										</Link>
										.<span className="light"> {menu.text}.</span>
									</div>
								);
							})}
						</div>
					</Parallax>

					<StaticImage
						src={`../../images/cafe/menuLine.jpg`}
						alt={t(
							"a top view of some delicious looking food, a toast with poached eggs and smoked salmon and a fruit bowl"
						)}
						className={"landingStripeImage"}
					/>
				</section>

				<section id="contact">
					<Parallax {...parallaxSettings}>
						<h2>
							<Trans>contact</Trans>.
						</h2>

						<div className="contactList">
							{contactList.map((detail) => {
								return (
									<div key={detail.name}>
										<span className="light">{detail.name}. </span>
										<div>
											{detail.internalLink ? (
												<Link to={detail.internalLink}>{detail.text}</Link>
											) : (
												<a href={detail.link}>{detail.text}</a>
											)}
											.
										</div>
									</div>
								);
							})}
						</div>

						{specialDates}
					</Parallax>

					<StaticImage
						src={`../../images/cafe/reservas.jpg`}
						alt={t(
							"a warmth lounge corner, two relaxing chairs and a small table with magazines"
						)}
						className={"landingStripeImage"}
					/>
				</section>

				<section id="about">
					<Parallax {...parallaxSettings}>
						<h2>
							<Trans>about</Trans>.
						</h2>

						<div>
							<p>
								<Trans>
									We are a <b>family owned and operated business</b>, inspired
									by the traits of some of the most iconic countries & what they
									have to offer.{" "}
									<b>Natural products and food made with love!</b> We aim to
									transmit simplicity, nature and joyfulness
								</Trans>
								.
							</p>
							<p>
								<Trans>
									We translate these values into our products and environments
									in order to offer our customers: “An Experience”. For this
									reason, we value craftsmanship, small producers and a family
									atmosphere
								</Trans>
								.
							</p>
							<p>
								<Trans>
									<b>Unwind, feel comfortable & have a snack</b>. BOSSA invites
									shoppers to enjoy “honest kitchen” in a cosy setting. Visitors
									experience a warm, relaxed atmosphere and are invited into the
									living room like guests calling on good friends
								</Trans>
								.
							</p>
						</div>
					</Parallax>

					<StaticImage
						src={`../../images/cafe/about.jpg`}
						alt={t("a waitress serving a home made baked croissant and muffin")}
						className={"landingStripeImage"}
					/>
				</section>
			</div>
		</Layout>
	);
}

// graphql query
export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`;
